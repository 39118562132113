<template>
  <div>
    <info-modal />
    <check-plan-modal />
    <confirmation-modal />
    <command-modal />
    <help-modal />
    <quick-win-modal />
    <program-modal />
    <team-picker v-if="teamsEnabled" />
    <customer-support-chat-boat />
  </div>
</template>

<script>
import InfoModal from '@/components/basic/info-modal/InfoModal';
import ConfirmationModal from '@/components/basic/confirmation-modal/ConfirmationModal';
import CheckPlanModal from '@/views/settings/account/CheckPlanModal';
import CommandModal from '@/components/command/CommandModal';
import TeamPicker from '@/views/settings/teams/TeamPicker';
import HelpModal from '@/components/basic/helm-modal/HelpModal';
import QuickWinModal from '@/components/programs/QuickWinModal';
import ProgramModal from '@/components/programs/ProgramModal';
import CustomerSupportChatBoat from '@/components/basic/customer-support-chat-boat/CustomerSupportChatBoat.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'AppModals',
  components: {
    ConfirmationModal,
    InfoModal,
    CheckPlanModal,
    HelpModal,
    CommandModal,
    TeamPicker,
    QuickWinModal,
    ProgramModal,
    CustomerSupportChatBoat,
  },
  computed: {
    ...mapGetters('users', ['currentUser']),

    teamsEnabled() {
      return this.isFeatureEnabled('teams');
    },
  },
};
</script>

import Module from '@/store/resource/common/module';
import CustomObjects from '@/store/resource/customObjects';
import api from '@/factories/api';
let module = new Module(new CustomObjects());

module.add('actions', {
  async fetchStandaloneObjects({ commit }) {
    try {
      const res = await api().get('custom/objects?standalone=true');
      const standaloneObjects = (res || []).filter((obj) => obj.standalone);
      commit('setStandaloneObjects', standaloneObjects);
    } catch (err) {
      throw err;
    }
  },
});

module.add('mutations', {
  setStandaloneObjects(state, objects) {
    state.standaloneObjects = objects;
  },
});

module.add('state', {
  standaloneObjects: [],
});

export default module.create();

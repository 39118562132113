<template>
  <v-menu
    right
    offset-x
    content-class="secondary-nav"
    v-model="isMenuOpen"
    eager
  >
    <template v-slot:activator="{ on }">
      <div v-on="on" class="help-nav__trigger" :class="{ active: isMenuOpen }">
        <app-sidebar-link
          :link="helpTriggerLink"
          :hideTooltips="disabledTooltip"
        />

        <notifications-count-badge :count="unreadNotificationsCount" />
      </div>
    </template>

    <v-list>
      <v-list-item
        v-for="(resource, idx) in resources"
        :key="idx"
        :href="resource.href"
        target="_blank"
      >
        <v-list-item-icon>
          <icon-base
            :fontIcon="resource.fontIcon"
            :icon="
              resource.fontIcon ? resource.icon : `sidebar/${resource.icon}`
            "
          />
        </v-list-item-icon>
        <v-list-item-title class="pr-2">
          {{ resource.text }}
        </v-list-item-title>
      </v-list-item>

      <customer-support-chat-boat-link />

      <intercom-link @unreadCountChange="updateUnreadNotificationsCount" />
    </v-list>
  </v-menu>
</template>

<script>
import { resources } from '../sidebarLinks';

import AppSidebarLink from '../links/AppSidebarLink.vue';
import IntercomLink from '../links/IntercomLink.vue';
import CustomerSupportChatBoatLink from '../links/CustomerSupportChatBoatLink.vue';
import NotificationsCountBadge from '../helpers/NotificationsCountBadge.vue';

export default {
  name: 'HelpNav',
  components: {
    AppSidebarLink,
    IntercomLink,
    CustomerSupportChatBoatLink,
    NotificationsCountBadge,
  },
  props: {
    disabledTooltip: Boolean,
  },
  data() {
    return {
      resources,
      isMenuOpen: false,
      unreadNotificationsCount: 0,
      helpTriggerLink: {
        text: 'Help',
        icon: 'help',
        path: '/help',
        disabled: true,
      },
    };
  },
  methods: {
    updateUnreadNotificationsCount(count) {
      this.unreadNotificationsCount = count;
    },
  },
};
</script>

<style lang="less">
.help-nav__trigger {
  position: relative;

  .notifications-count-badge {
    left: 20px;
    top: 2px;
  }
}
</style>

<template>
  <v-slide-x-reverse-transition>
    <div class="chatboat" v-if="isChatBoatVisible">
      <div class="chatboat__header">
        <icon-base icon="custom/ai" class="pr-2" />
        Ask CY

        <v-spacer />

        <v-btn icon class="mr-1" @click="isChatBoatVisible = false">
          <v-icon color="light">close</v-icon>
        </v-btn>
      </div>
      <div class="chatboat__body" ref="messagesContainer">
        <div
          v-for="(message, index) in messages"
          :key="index"
          class="chatboat__message"
          :class="`chatboat__message--${message.role}`"
        >
          <div
            class="chatboat__message-content"
            v-html="messageContent(message)"
          ></div>
        </div>

        <v-slide-y-transition>
          <div v-if="isFeedbackVisible" class="chatboat__feedback">
            <div class="chatboat__feedback-message" v-if="isFeedbackSent">
              Thank you for your feedback!
            </div>

            <div class="chatboat__feedback-message" v-else>
              Was this message helpful?
              <div class="chatboat__feedback-actions">
                <v-btn
                  icon
                  small
                  color="appMain"
                  class="mr-2"
                  :disabled="isFeedbackSending"
                  @click="sendBotFeedback(1)"
                >
                  <v-icon small>thumb_up</v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  color="appMain"
                  class="mr-1"
                  :disabled="isFeedbackSending"
                  @click="sendBotFeedback(0)"
                >
                  <v-icon small>thumb_down</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-slide-y-transition>
      </div>
      <div class="chatboat__form">
        <v-progress-linear
          v-if="isLoading"
          height="3"
          indeterminate
          color="appMain"
          class="chatboat__progress"
        />

        <v-textarea
          placeholder="Type your message..."
          class="no-label"
          hide-details
          rows="4"
          auto-grow
          v-model="userQuery"
          :disabled="isLoading"
          autofocus
          @keyup.enter="sendMessage"
        />

        <v-btn
          icon
          color="appMain"
          class="chatboat__form-send"
          :disabled="!userQuery || isLoading"
          @click="sendMessage"
        >
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </div>
    </div>
  </v-slide-x-reverse-transition>
</template>

<script>
import EventBus from '@/factories/eventBus';
import { vueEnv } from '@/globals';
const domain = vueEnv('chatboat_path');

export default {
  name: 'CustomerSupportChatBoat',
  data() {
    return {
      isChatBoatVisible: false,
      isLoading: false,
      userQuery: null,
      messages: [],
      isFirstChunk: false,
      requestId: null,
      responseId: null,

      // feedback
      isFeedbackVisible: false,
      isFeedbackSending: false,
      isFeedbackSent: false,
    };
  },
  methods: {
    toggleChatBoat() {
      this.hideIntercom();
      this.isChatBoatVisible = !this.isChatBoatVisible;
    },

    hideIntercom() {
      if (!window.Intercom || this.isChatBoatVisible) return;
      Intercom('hide');
    },

    async sendMessage() {
      const userQuery = this.userQuery.trim();
      if (!userQuery) return;

      this.addUserMessage(userQuery);

      this.clearFeedback();

      try {
        this.isLoading = true;

        const response = await fetch(
          `${domain}/?q=${encodeURIComponent(userQuery)}`
        );
        await this.handleStreamedResponse(response);
      } catch (error) {
        console.error('Error during fetch:', error);
        this.isLoading = false;
      }

      this.scrollToBottom();
    },

    addUserMessage(userQuery) {
      this.messages.push({
        role: 'user',
        content: userQuery,
      });

      this.userQuery = null;

      this.scrollToBottom();
    },

    async handleStreamedResponse(response) {
      if (!response.body) throw new Error('No response body found');

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      this.isFirstChunk = true;
      let partialLine = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        partialLine = this.processChunk(chunk, partialLine);
      }

      this.finalizeMessage();
    },

    processChunk(chunk, partialLine) {
      const lines = (partialLine + chunk).split('\n');
      partialLine = lines.pop();

      lines.forEach((line) => {
        if (line === 'data: [DONE]') {
          this.isLoading = false;
        } else if (line.startsWith('data: ')) {
          try {
            const jsonData = JSON.parse(line.slice(6));

            if (jsonData.requestId) {
              this.requestId = jsonData.requestId;
            }

            if (jsonData.response) {
              this.updateBotMessage(jsonData.response);
            }
          } catch (e) {
            console.log('Error parsing JSON:', e);
          }
        }
      });

      return partialLine;
    },

    updateBotMessage(partialMessage) {
      if (this.isFirstChunk) {
        this.messages.push({
          role: 'bot',
          content: partialMessage,
        });

        this.isFirstChunk = false;
      } else {
        this.messages[this.messages.length - 1].content += partialMessage;
      }

      this.scrollToBottom();
    },

    finalizeMessage() {
      this.isLoading = false;
      this.isFeedbackVisible = true;
      this.sendBotResponseToServer(
        this.requestId,
        this.messages[this.messages.length - 1].content
      );
    },

    async sendBotResponseToServer(requestId, responseBody) {
      try {
        const response = await fetch(
          `${domain}/process-response?id=${requestId}`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ response: responseBody }),
          }
        );

        if (!response.ok) {
          console.log('Error sending bot response:', response.statusText);
        } else {
          const responseData = await response.json();
          if (responseData?.response_id) {
            this.responseId = responseData.response_id;
          }
        }
      } catch (error) {
        console.log('Error during asynchronous request to server:', error);
      }
    },

    async sendBotFeedback(wasHelpful) {
      try {
        this.isFeedbackSending = true;
        await fetch(`${domain}/submit-feedback?id=${this.responseId}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ was_helpful: wasHelpful }),
        });

        this.isFeedbackSent = true;

        setTimeout(() => {
          this.clearFeedback();
        }, 2000);
      } catch (error) {
        console.error('Error sending feedback:', error);
      } finally {
        this.isFeedbackSending = false;
      }
    },

    clearFeedback() {
      this.isFeedbackVisible = false;
      this.isFeedbackSent = false;
      this.responseId = null;
    },

    messageContent(message) {
      return message.role === 'bot'
        ? this.formatMarkdown(message.content)
        : message.content;
    },

    formatMarkdown(content) {
      return content
        .replace(
          /(#+)\s(.+)/g,
          (match, hashes, text) =>
            `<h${hashes.length}>${text}</h${hashes.length}>`
        )
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/\*(.*?)\*/g, '<em>$1</em>')
        .replace(/_(.*?)_/g, '<em>$1</em>')
        .replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>')
        .replace(/\n/g, '<br>')
        .replace(/^- (.+)/gm, '<ul><li>$1</li></ul>')
        .replace(/^\d+\.\s(.+)/gm, '<ol><li>$1</li></ol>');
    },

    async scrollToBottom() {
      const tolerance = 10;
      const messagesContainer = this.$refs.messagesContainer;

      if (!messagesContainer) return;

      const isScrollAtBottom =
        messagesContainer.scrollTop + messagesContainer.clientHeight >=
        messagesContainer.scrollHeight - tolerance;

      if (!isScrollAtBottom) return;

      await this.$nextTick();

      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    },
  },
  created() {
    EventBus.$on('toggleChatBoat', this.toggleChatBoat);
    EventBus.$on('closeChatBoat', () => {
      if (this.isChatBoatVisible) {
        this.isChatBoatVisible = false;
      }
    });
  },
};
</script>

<style lang="less">
.chatboat {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px;
  width: 440px;
  height: 600px;
  max-width: calc(100vw - 40px);
  max-height: calc(100vh - 40px);
  border-radius: @base-radius;
  box-shadow: @base-shadow4;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 9999;

  &__header {
    padding: 8px 8px 8px 16px;
    background: #2c3e50;
    font-family: var(--v-headers-font-base);
    font-weight: 600;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 20px;

    svg {
      fill: #fff;
    }
  }

  &__body {
    flex: 1;
    background: #f7f7f7;
    padding: 16px;
    overflow: auto;

    .message {
      border: 1px solid #ccc;
      padding: 12px;
      border-radius: @base-radius;
    }
  }

  &__form {
    display: flex;
    border-top: 1px solid #ccc;
    position: relative;
    background: #fff;

    .v-input__slot {
      margin-bottom: 0 !important;
      padding-right: 30px !important;
    }

    &-send {
      position: absolute !important;
      bottom: 6px;
      right: 6px;
    }
  }

  &__progress {
    position: absolute;
    top: -3px;
    left: 0;
    right: 0;
    width: 100%;
  }

  .chatboat__message {
    padding: 12px;
    margin-bottom: 16px;
    border-radius: @base-radius;
    box-shadow: @base-shadow;

    &--bot {
      margin-right: 48px;
      background: #fff;
    }

    &--user {
      margin-left: 48px;
      background: #68cdff59;
    }
  }

  &__feedback {
    display: flex;

    &-message {
      border-radius: @base-radius;
      border: 1px solid #ccc;
      background: #fff;
      margin: 0 auto;
      display: flex;
      align-items: center;
      font-weight: 600;
      padding: 6px 12px;
    }

    &-actions {
      margin-left: 12px;
    }
  }
}
</style>
